<template>
  <card>
    <base-alert v-if="message" :type="code" v-html="message">
        {{ message }}
    </base-alert>
    <form @submit.prevent="submitForm">
      <base-input
        label="Email address"
        type="email"
        v-model.trim="model.email"
        addonLeftIcon="tim-icons icon-email-85"
        placeholder="Email"
      >
      </base-input>

      <base-input
        label="Password"
        type="password"
        v-model.trim="model.password"
        addonLeftIcon="tim-icons icon-key-25"
        placeholder="Password"
      >
      </base-input>

      <base-button native-type="submit" :loading="isLoading" type="primary">Login</base-button>
      <br style="clear:both;" /><br style="clear:both;" />

      <router-link to="/register">
        <div class="pull-right">
          <h6><a class="link footer-link"> Create Account </a></h6>
        </div>
      </router-link>
    </form>
  </card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      model: {
        email: "",
        password: ""
      },
      isLoading: false,
      code: null,
      message: null,
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      console.log(this.model);
      fetch(process.env.VUE_APP_BASE_URL + "token/login", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.model),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.isLoading = false;

          if (data.hasOwnProperty('code')) 
          {
              this.message = data.message;
              this.code = data.code;

              if(data.code == 'success')
              {

                    const actionPayload = {
                        email: this.model.email,
                        token: data.token,
                        gravatar_url: data.gravatar_url
                    };

                    this.$store.dispatch('login', actionPayload);

                    const redirectUrl = '/' + (this.$route.query.redirect || 'dashboard');
                    this.$router.replace(redirectUrl);
              }

          }
          else
          {
              this.code = 'danger';
              
              this.message = '';
              for (const key in data) {
                this.message += data[key]+'<br />';
                }
              
          }

          if(data.hasOwnProperty('insert_id'))
          {
              this.model.id = data.insert_id;
          }

          //this.model = data.model;
        }).catch((error) => {
          this.message = error;
          this.code = 'danger';
        });
    }
  }
};
</script>
<style>
.login-page label {
  clear: both;
  display: block;
  position: relative;
  width: 100%;
  padding: 10px 0px 10px 0px;
}
</style>
