<template>
  <card>
    <h5 slot="header" class="title">Edit/Add Sell High Bot</h5>

    <base-alert v-if="message" :type="code" v-html="message">
        {{ message }}
    </base-alert>

    <div class="row">

<!--
    
      <div class="col-md-6 pr-md-1">
        <base-input
          label="Pair Symbol (example: BTC/USDT)"
          placeholder="Pair Symbol"
          v-model="model.pair_symbol"
        >
        </base-input>
      </div>
-->
      <div class="col-md-6 pr-md-1">
        <base-input label="Pair Symbol (example: BTC/USDT)" placeholder="Pair Symbol">
          <select v-model="model.pair_symbol" class="form-control" id="pairSelect" v-on:change="changePair(rowId, $event)">
            <option v-for="item in market_pairs" :key="item.c0">{{ item.c0 }}</option>
          </select>
        </base-input>
      </div>


      <div class="col-md-6 pr-md-1">
        <base-input
          label="Trigger Price (in USDT)"
          placeholder="Trigger Price"
          v-model="model.trigger_price"
        >
        </base-input>
      </div>
      <div class="col-md-6 pr-md-1">
        <base-input label="Status (On start define WORKING)" placeholder="Status">
          <select v-model="model.status" class="form-control" id="statusSelect">
            <option>WORKING</option>
            <option>COMPLETED</option>
            <option>FAILED</option>
            <option>PREPARING</option>
          </select>
        </base-input>
      </div>
      <div class="col-md-6 pr-md-1">
        <base-input
          label="Drop %"
          placeholder="Drop %"
        >
          <select v-model="model.drop_percent" class="form-control" id="drop_percentSelect">
            <option value="5">-5%</option>
            <option value="10">-10%</option>
            <option value="15">-15%</option>
            <option value="20">-20%</option>
          </select>
        </base-input>
      </div>
      <div class="col-md-6 pr-md-1">
        <base-input
          label="Sell Quantity (In coins)"
          placeholder="Sell Quantity"
          v-model="model.sell_quantity"
        >
        </base-input>
      </div>
    </div>
    <base-button v-on:click="saveData(model.id)" slot="footer" :loading="isLoading" type="primary" fill>Save</base-button>
  </card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    market_pairs: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
 data() {
    return {
      isLoading: false,
      code: null,
      message: null,
      rowId: null
    };
  },
  methods: {
    changePair(rowId, event)
    {
        for (const key in this.market_pairs) {
            if(this.market_pairs[key].c0 == event.target.value)
            {
                this.model.trigger_price = this.market_pairs[key].c2;
                break;
            }

            this.model.status = 'PREPARING';
            this.model.drop_percent = '5';
        }
    },
    saveData(botId) {
      this.isLoading = true;
      //console.log(this.model);

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/save?auth="+token, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.model),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.isLoading = false;

          if (data.hasOwnProperty('code')) 
          {
              this.message = data.message;
              this.code = data.code;
          }
          else
          {
              this.code = 'danger';
              
              this.message = '';
              for (const key in data) {
                this.message += data[key]+'<br />';
                }
              
          }

          if(data.hasOwnProperty('insert_id'))
          {
              this.model.id = data.insert_id;
          }

          //this.model = data.model;
        }).catch((error) => {
          this.message = error;
          this.code = 'danger';
        });
    }
  },
  created() {
    //console.log(this.market_pairs);
    //console.log(this.model);
    //this.market_pairs = ['BTC' , 'ETH'];
  }
};
</script>
<style>
</style>
