<template>
  <card>
    <base-alert v-if="message" :type="code" v-html="message">
        {{ message }}
    </base-alert>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="Email address"
            type="email"
            v-model="model.email"
            placeholder="mike@email.com"
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
            label="Password"
            type="password"
            placeholder="Password"
            v-model="model.password"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="First Name"
            v-model="model.firstName"
            placeholder="First Name"
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
            label="Last Name"
            v-model="model.lastName"
            placeholder="Last Name"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Address"
            v-model="model.address"
            placeholder="Home Address"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Public API key (from ascendex.com, formerly bitmax)"
            v-model="model.public_key"
            placeholder="Public API key"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Secret API key (from ascendex.com, formerly bitmax)"
            v-model="model.secret_key"
            placeholder="Secret API key"
          >
          </base-input>
        </div>
      </div>

      <base-button native-type="submit" :loading="isLoading" type="primary"
        >Create Account</base-button
      >
      <br style="clear:both;" /><br style="clear:both;" />
      <router-link to="/login">
        <div class="pull-right">
          <h6><a class="link footer-link"> Login </a></h6>
        </div>
      </router-link>
    </form>
  </card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      model: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        address: "",
        public_key: "",
        secret_key: ""
      },
      isLoading: false,
      code: null,
      message: null
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      console.log(this.model);
      fetch(process.env.VUE_APP_BASE_URL + "token/register", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.model)
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.isLoading = false;

          if (data.hasOwnProperty("code")) {
            this.message = data.message;
            this.code = data.code;

            if(data.code == 'success')
            {
                const redirectUrl = '/' + (this.$route.query.redirect || 'dashboard');
                this.$router.replace(redirectUrl);
            }


          } else {
            this.code = "danger";

            this.message = "";
            for (const key in data) {
              this.message += data[key] + "<br />";
            }
          }

          if (data.hasOwnProperty("insert_id")) {
            this.model.id = data.insert_id;
          }

          //this.model = data.model;
        })
        .catch(error => {
          this.message = error;
          this.code = "danger";
        });
    }
  }
};
</script>
<style>
.login-page label {
  clear: both;
  display: block;
  position: relative;
  width: 100%;
  padding: 10px 0px 10px 0px;
}
</style>
