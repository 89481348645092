<template>
  <div class="row">
    <div class="col-md-12">
      <edit-profile-form :model="model"> </edit-profile-form>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
export default {
  components: {
    EditProfileForm
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        address: "",
        public_key: "",
        secret_key: ""
      },
      isLoading: false
    };
  },
  methods: {
    loadData() {
      this.isLoading = true;

      var token = this.$store.getters.token;

      fetch(
        process.env.VUE_APP_BASE_URL + "profile/fetch?auth=" + token
      )
        .then(response => {
          if (response.status !== 200) {
            throw new Error(response.status);
          }

          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;

          if (data.model) this.model = data.model;
        })
        .catch(error => {
          if (error == "Error: 401") {
            alert("Authentication failed");
            this.$store.dispatch("logout");
            this.$router.replace("/login");
            return;
          }

          alert(error);
        });
    }
  },
  mounted() {
    this.loadData(this.botId);
  }
};
</script>
<style>
</style>
