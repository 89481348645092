<template>
  <div class="row">
    <div class="col-md-12">
      <p v-if="isLoading">Loading...</p>
      <edit-bot-form v-if="!isLoading" :model="model" :market_pairs="market_pairs"> </edit-bot-form>
    </div>
  </div>
</template>
<script>
import EditBotForm from "./BotEdit/EditBotGridForm";
export default {
  props: ["botId"],
  components: {
    EditBotForm
  },
  data() {
    return {
      model: {
        id: "",
        pair_symbol: "",
        status: "",
        step_price: "",
        step_trans_quantity: "",
        upper_limit_price: "",
        lower_limit_price: ""
      },
      isLoading: false,
      market_pairs: []
    };
  },
  methods: {
    loadData(botId) {
      this.isLoading = true;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/fetch_grid/" + botId + '?auth='+token)
        .then(response => {

          if (response.status !== 200) {
            throw new Error(response.status);
          }

          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;

          if(data.model)
            this.model = data.model;
        })
        .catch((error) => {

          if (error == 'Error: 401') {
            alert('Authentication failed');
            this.$store.dispatch('logout');
            this.$router.replace('/login');
            return;
          }

          alert(error);
          
        });
    },
    loadDropdownPairs()
    {
      fetch(
        process.env.VUE_APP_BASE_URL+'market/show_all'
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          const results = [];
          for (const id in data) {
            results.push({
              c0: data[id].symbol.toString(),
              c1: data[id].open.toString(),
              c2: data[id].close.toString(),
              c3: data[id].high.toString(),
              c4: data[id].low.toString(),
              c5: data[id].volume.toString(),
            });
          }

          this.market_pairs = results;
        });
    }
  },
  mounted() {

    this.loadDropdownPairs();

    if (this.botId) this.loadData(this.botId);
  }
};
</script>
<style>
</style>
