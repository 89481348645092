<template>
    <div class="row">
      <div class="col-12">
        <card :title="table1.title">
          <div class="table-responsive">
              <p v-if="isLoading">Loading...</p>
            <base-table  
                v-if="!isLoading" 
                        :data="table1.data"
                        :columns="table1.columns"
                        thead-classes="text-primary">
            </base-table>
          </div>
        </card>
      </div>
    </div>
</template>
<script>
import { BaseTable } from "@/components";
const tableColumns = ["Symbol", "Open", "Close", "High", "Low", "Volume"];
const tableData = [];

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table1: {
        title: "Market data",
        columns: [...tableColumns],
        data: [...tableData]
      },
      isLoading: false
    };
  },
  methods: {
    loadMarket() {
        this.isLoading = true;
      fetch(
        process.env.VUE_APP_BASE_URL+'market/show_all'
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;

          const results = [];
          for (const id in data) {
            results.push({
              0: data[id].symbol.toString(),
              1: data[id].open.toString(),
              2: data[id].close.toString(),
              3: data[id].high.toString(),
              4: data[id].low.toString(),
              5: data[id].volume.toString(),
            });
          }

          this.table1.data = results;
        });
    }
  },
  mounted(){
    this.loadMarket();










  }
};
</script>
<style>
</style>
