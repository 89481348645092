<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>

    <base-alert v-if="message" :type="code" v-html="message">
        {{ message }}
    </base-alert>

    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input
          label="Email address"
          type="email"
          v-model="model.email"
          placeholder="mike@email.com"
        >
        </base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input
          label="Password"
          type="password"
          placeholder="Password"
          v-model="model.password"
        >
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input
          label="First Name"
          v-model="model.firstName"
          placeholder="First Name"
        >
        </base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input
          label="Last Name"
          v-model="model.lastName"
          placeholder="Last Name"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <base-input
          label="Address"
          v-model="model.address"
          placeholder="Home Address"
        >
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <base-input
          label="Public API key"
          v-model="model.public_key"
          placeholder="Public API key"
        >
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <base-input
          label="Secret API key"
          v-model="model.secret_key"
          placeholder="Secret API key"
        >
        </base-input>
      </div>
    </div>

    <base-button v-on:click="saveData()" slot="footer" :loading="isLoading" type="primary" fill>Save</base-button>

    <base-button v-on:click="terminateAccount()" slot="footer" class="pull-right" :loading="isLoading" type="danger" fill>Terminate Account</base-button>
  </card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isLoading: false,
      code: null,
      message: null
    };
  },
  methods: {
   terminateAccount() {
      //alert(id);

      if(!confirm("Are you sure? Your account will be terminated immediately"))return;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "profile/delete?auth="+token, {
        method: "GET"
      })
        .then(response => {
          if (response.ok) {
              
            this.$store.dispatch('logout');
            this.$router.replace('/login');

            return response.json();
          }
        })
        .catch(error => {
          //console.error("Error:", error);
          alert(error);
        });
    },
    saveData() {
      this.isLoading = true;
      //console.log(this.model);

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "profile/save?auth="+token, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.model)
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.isLoading = false;

          if (data.hasOwnProperty("code")) {
            this.message = data.message;
            this.code = data.code;
          } else {
            this.code = "danger";

            this.message = "";
            for (const key in data) {
              this.message += data[key] + "<br />";
            }
          }
/*
          if (data.hasOwnProperty("insert_id")) {
            this.model.id = data.insert_id;
          }
*/
          //this.model = data.model;
        })
        .catch(error => {
          this.message = error;
          this.code = "danger";
        });
    }
  }
};
</script>
<style>
</style>
