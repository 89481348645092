<template>
<div>
  <div class="row">
    <div class="col-12">
      <card :title="table1.title">

          <router-link :to="rowAddLink()">
                <base-button
                  type="primary"
                >
                Create Bot Sell High
                </base-button>
        </router-link>

        <div class="table-responsive">
          <p v-if="isLoading">Loading...</p>
          <base-table
            v-if="!isLoading"
            :data="table1.data"
            :columns="table1.columns"
            thead-classes="text-primary"
          >
            <template slot-scope="{ row }">
              <td>{{ row.c0 }}</td>
              <td>{{ row.c1 }}</td>
              <td>{{ row.c2 }}</td>
              <td>{{ row.c3 }}</td>
              <td>{{ row.c4 }}</td>
              <td>{{ row.c5 }}</td>
              <td>{{ row.c6 }}</td>
              <td>{{ row.c7 }}</td>
              <td class="td-actions">
                <router-link :to="rowEditLink(row.c0)">
                    <base-button type="success" size="sm" icon>
                    <i class="tim-icons icon-settings"></i>
                    </base-button>
                </router-link>
                &nbsp;
                <base-button
                  v-on:click="deleteRow(row.c0)"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </td>
            </template>
          </base-table>
        </div>
      </card>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <card :title="table2.title">

          <router-link :to="rowAddBuyLowLink()">
                <base-button
                  type="primary"
                >
                Create Bot Buy Low
                </base-button>
        </router-link>

        <div class="table-responsive">
          <p v-if="isLoading">Loading...</p>
          <base-table
            v-if="!isLoading"
            :data="table2.data"
            :columns="table2.columns"
            thead-classes="text-primary"
          >
            <template slot-scope="{ row }">
              <td>{{ row.c0 }}</td>
              <td>{{ row.c1 }}</td>
              <td>{{ row.c2 }}</td>
              <td>{{ row.c3 }}</td>
              <td>{{ row.c4 }}</td>
              <td>{{ row.c5 }}</td>
              <td>{{ row.c6 }}</td>
              <td>{{ row.c7 }}</td>
              <td class="td-actions">
                <router-link :to="rowEditBuyLowLink(row.c0)">
                    <base-button type="success" size="sm" icon>
                    <i class="tim-icons icon-settings"></i>
                    </base-button>
                </router-link>
                &nbsp;
                <base-button
                  v-on:click="deleteBuyLowRow(row.c0)"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </td>
            </template>
          </base-table>
        </div>
      </card>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <card title="Bots Grid">

          <router-link :to="rowAddGridLink()">
                <base-button
                  type="primary"
                >
                Create Bot Grid
                </base-button>
        </router-link>

        <div class="table-responsive">
          <p v-if="isLoading">Loading...</p>
          <base-table
            v-if="!isLoading"
            :data="table3.data"
            :columns="table3.columns"
            thead-classes="text-primary"
          >
            <template slot-scope="{ row }">
              <td>{{ row.c0 }}</td>
              <td>{{ row.c1 }}</td>
              <td>{{ row.c2 }}</td>
              <td>{{ row.c3 }}</td>
              <td>{{ row.c4 }}</td>
              <td>{{ row.c5 }}</td>
              <td>{{ row.c6 }}</td>
              <td>{{ row.c7 }}</td>
              <td class="td-actions">
                <router-link :to="rowEditGridLink(row.c0)">
                    <base-button type="success" size="sm" icon>
                    <i class="tim-icons icon-settings"></i>
                    </base-button>
                </router-link>
                &nbsp;
                <base-button
                  v-on:click="deleteGridRow(row.c0)"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </td>
            </template>
          </base-table>
        </div>
      </card>
    </div>
  </div>

</div>
</template>
<script>
import { BaseTable } from "@/components";

const tableColumns = [
  "#",
  "Pair symbol",
  "Status",
  "Trigger Price",
  "Drop %",
  "Sell Qty",
  "High price",
  "Sell price",
  ""
];

const tableBuyLowColumns = [
  "#",
  "Pair symbol",
  "Status",
  "Trigger Price",
  "Incr %",
  "Buy Qty",
  "Low price",
  "Buy price",
  ""
];

const tableGridColumns = [
  "#",
  "Pair symbol",
  "Status",
  "Trigger Price",
  "Current Step",
  "Date Start",
  "Low limit",
  "Upp limit",
  ""
];

const tableData = [];

const tableBuyLowData = [];

const tablGridData = [];

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table1: {
        title: "Bots Sell High",
        columns: [...tableColumns],
        data: [...tableData]
      },
      table2: {
        title: "Bots Buy Low",
        columns: [...tableBuyLowColumns],
        data: [...tableBuyLowData]
      },
      table3: {
        title: "Bots Grid",
        columns: [...tableGridColumns],
        data: [...tablGridData]
      },
      isLoading: false
    };
  },
  provide() {
    return {
      botsdata: this.table1.data,
      bots_buy_low: this.table2.data,
      bots_grid: this.table3.data
    };
  },
  computed: {
    
  },
  methods: {
    loadTable() {
      this.isLoading = true;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/show_combined?auth="+token)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;

          var results = [];
          var id;

          for (id in data.bot_sell_high) {
            results.push({
              c0: data.bot_sell_high[id].id.toString(),
              c1: data.bot_sell_high[id].pair_symbol.toString(),
              c2: data.bot_sell_high[id].status.toString(),
              c3: data.bot_sell_high[id].trigger_price.toString(),
              c4: data.bot_sell_high[id].drop_percent.toString(),
              c5: data.bot_sell_high[id].sell_quantity.toString(),
              c6: data.bot_sell_high[id].highest_price.toString(),
              c7: data.bot_sell_high[id].current_sell_price.toString()
            });
          }

          this.table1.data = results;

          results = [];
          for (id in data.bot_buy_low) {
            results.push({
              c0: data.bot_buy_low[id].id.toString(),
              c1: data.bot_buy_low[id].pair_symbol.toString(),
              c2: data.bot_buy_low[id].status.toString(),
              c3: data.bot_buy_low[id].trigger_price.toString(),
              c4: data.bot_buy_low[id].incr_percent.toString(),
              c5: data.bot_buy_low[id].buy_quantity.toString(),
              c6: data.bot_buy_low[id].lowest_price.toString(),
              c7: data.bot_buy_low[id].current_buy_price.toString()
            });
          }

          this.table2.data = results;

          results = [];
          for (id in data.bot_grid) {
            results.push({
              c0: data.bot_grid[id].id.toString(),
              c1: data.bot_grid[id].pair_symbol.toString(),
              c2: data.bot_grid[id].status.toString(),
              c3: data.bot_grid[id].trigger_price.toString(),
              c4: data.bot_grid[id].current_step_price.toString(),
              c5: data.bot_grid[id].date_created.toString(),
              c6: data.bot_grid[id].lower_limit_price.toString(),
              c7: data.bot_grid[id].upper_limit_price.toString()
            });
          }

          this.table3.data = results;

        });
    },
    rowAddLink() {
       return '/bots/edit/';
    },
    rowEditLink(rowId) {
       return '/bots/edit/' + rowId + '/';
    },
    deleteRow(rowId) {
      //alert(id);

      if(!confirm("Are you sure?"))return;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/delete/" + rowId+"?auth="+token, {
        method: "GET"
      })
        .then(response => {
          if (response.ok) {

            this.table1.data = this.table1.data.filter((row) => row.c0 !== rowId);

            return response.json();
          }
        })
        .catch(error => {
          //console.error("Error:", error);
          alert(error);
        });
    },
    rowAddBuyLowLink()
    {
        return '/bots/edit_buy_low/';
    },
    rowEditBuyLowLink(rowId) {
       return '/bots/edit_buy_low/' + rowId + '/';
    },
    deleteBuyLowRow(rowId) {
      //alert(id);

      if(!confirm("Are you sure?"))return;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/delete_buy_low/" + rowId+"?auth="+token, {
        method: "GET"
      })
        .then(response => {
          if (response.ok) {

            this.table2.data = this.table2.data.filter((row) => row.c0 !== rowId);

            return response.json();
          }
        })
        .catch(error => {
          //console.error("Error:", error);
          alert(error);
        });
    },
    rowAddGridLink()
    {
        return '/bots/edit_grid/';
    },
    rowEditGridLink(rowId) {
       return '/bots/edit_grid/' + rowId + '/';
    },
    deleteGridRow(rowId) {
      //alert(id);

      if(!confirm("Are you sure?"))return;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/delete_grid/" + rowId+"?auth="+token, {
        method: "GET"
      })
        .then(response => {
          if (response.ok) {

            this.table3.data = this.table3.data.filter((row) => row.c0 !== rowId);

            return response.json();
          }
        })
        .catch(error => {
          //console.error("Error:", error);
          alert(error);
        });
    }
  },
  mounted() {
    this.loadTable();
  }
};
</script>
<style>
</style>
